export const SOLANA_NETWORK = process.env.NEXT_PUBLIC_SOLANA_NETWORK || 'mainnet'
export const SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL || 'https://api.thestonedfrogs.io'

export const FRONT_END_URL = "https://bakepad.bakedberserk.com";
export const CLIENT_ID = "d3b19498-54e8-4f23-bb64-185240c9c1d2";

export const CLIENT_NAME = "Baked Berserk";
export const PROJECT_NAME = "Bakepad";
export const TWITTER_USERNAME = "bakedberserk";
export const GTAG_ID = "G-CYETV6VKH4";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCebC1BauUW6vzWawVKPEu_myn4Kj3kz1A",
  authDomain: "baked-berserk.firebaseapp.com",
  projectId: "baked-berserk",
  storageBucket: "baked-berserk.appspot.com",
  messagingSenderId: "186602200903",
  appId: "1:186602200903:web:d3d4146985fb1c41b87662",
  measurementId: "G-CYETV6VKH4"
}

export const COLORS = {
  light: {
    primary: "#de3612",
    secondary: "white",
    background: '#111827',
  },
  dark: {
    primary: "#d1b37d",
    secondary: "white",
    background: '#111827',
  },
};
export const S3_FOLDER_PATH =
  "https://s3.us-west-1.amazonaws.com/cdn.thestonedfrogs.io/_assets/baked_berserk/";

export const EXTRA_MENU = [];

